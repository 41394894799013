<!-- #################################################################################### -->
<!-- ###### HERINCO                                                                ###### -->
<!-- ###### @author: John David Vásquez Serna                                      ###### -->
<!-- ###### @date: Noviembre 2023                                                  ###### -->
<!-- #################################################################################### -->

<!-- #################################################################################### -->
<!-- ###### Sección de HTML                                                        ###### -->
<!-- #################################################################################### -->
<template>
    <v-main style="padding: 0 1rem !important;">

        <!-- filtros de busqueda y botón para agregar un relación -->
        <div class="d-flex pt-3">
            <v-text-field class="me-2" outlined dense hide-details label="Número de contrato"
                v-model="buscadorIdContrato" @paste.prevent @keypress="validarLetra" @keyup="listarRelaciones()">
            </v-text-field>
            
            <v-text-field class="filtros me-2" outlined dense hide-details label="Nombre del contrato"
                v-model="buscadorNombreContrato" @keyup="listarRelaciones()">
            </v-text-field>

            <v-text-field class="filtros me-2" outlined dense hide-details label="Código del producto"
                v-model="buscadorIdProducto" @paste.prevent @keypress="validarLetra" @keyup="listarRelaciones()">
            </v-text-field>

            <v-text-field class="filtros" outlined dense hide-details label="Nombre del producto"
                v-model="buscadorNombreProducto" @keyup="listarRelaciones()">
            </v-text-field>

            <v-tooltip left color="success">
                <template v-slot:activator="{ on, attrs }">
                    <v-btn small class="ms-2" fab color="success" v-bind="attrs" v-on="on"
                        @mousedown.prevent="dialog = true">
                        <v-icon> add </v-icon>
                    </v-btn>
                </template>
                <span>Agregar</span>
            </v-tooltip>
        </div>

        <!-- dialogo para crear una relación -->
        <v-dialog v-model="dialog" persistent transition="dialog-bottom-transition" max-width="49.7rem">
            <v-card>
                <v-tabs v-model="opciones" background-color="blue darken-4" dark class="v-tabs--fixed-tabs">
                    <v-tabs-slider color="yellow" class="v-tabs"></v-tabs-slider>
                    <v-tab style="margin-right: 40px; margin-left: 17px !important;">Agregar relaciones uno a uno</v-tab>
                    <v-tab>Agregar relaciones masivas</v-tab>
                </v-tabs>

                <!-- formulario crear una relación -->
                <v-tabs-items v-model="opciones">
                    <v-tab-item>
                        <v-card-text class="pa-5">
                            <validation-observer ref="observer" v-slot="{ invalid }">
                                <v-form ref="nuevaRelacion" class="mt-3">

                                    <!-- Campo para buscar y elegir un contrato -->
                                    <div>
                                        <v-menu>
                                            <template v-slot:activator="{ on }">
                                                <!-- Campo de busqueda -->
                                                <ValidationProvider ref="contratos" v-slot="{ errors }" :rules="{ required: true, contratoVacio: true}">
                                                    <v-text-field v-model="buscarTextoContrato" label="Contrato" dense outlined :error-messages="buscarTextoErrorContrato || errors"
                                                        @input="buscarContrato" v-on="on">
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <!-- Lista de los contratos-->
                                            <v-list v-show="mostrarListaContrato && contratosFiltrados.length > 0" class="resultadoLista" style="margin-top: 2.5rem;">
                                                <v-list-item v-for="contratos  in  contratosFiltrados" :key="contratos.value" 
                                                    @click="seleccionDeContrato(contratos)">
                                                    <v-list-item-title>{{ contratos.text }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>

                                    <!-- Campo para buscar y elegir un producto -->
                                    <div>
                                        <v-menu>
                                            <template v-slot:activator="{ on }">
                                                <!-- Campo de busqueda -->
                                                <ValidationProvider ref="productos" v-slot="{ errors }" :rules="{ required: true, productoVacio: true}">
                                                    <v-text-field v-model="buscarTextoProducto" label="Producto" dense outlined :error-messages="datosExistentesError || buscarTextoErrorProducto || errors"
                                                        @input="buscarProducto" v-on="on">
                                                    </v-text-field>
                                                </ValidationProvider>
                                            </template>
                                            <!-- Lista de los productos-->
                                            <v-list v-show="mostrarListaProducto && productosFiltrados.length > 0" class="resultadoLista" style="margin-top: 2.5rem; width: 47.2rem;">
                                                <v-list-item v-for="productos  in  productosFiltrados" :key="productos.value" 
                                                    @click="seleccionDeProducto(productos)">
                                                    <v-list-item-title>{{ productos.text }}</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>

                                    <!-- Campos para el tope de unidad y el tope de lista utilizado en la relación -->
                                    <div class="d-flex">
                                        <div class="d-flex me-2 align-items-center">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true, }">
                                                <v-text-field outlined id="topeUnidad" v-model="nuevaRelacion.topeUnidad"
                                                    dense :error-messages="errors" style="width: 23.3rem" label="Tope unidad" 
                                                    @keypress="validarLetra" @paste.prevent>
                                                </v-text-field>
                                            </validation-provider>
                                        </div>
                                        <div class="d-flex me-2 align-items-center">
                                            <validation-provider v-slot="{ errors }" :rules="{ required: true, }">
                                                <v-text-field outlined id="topeLista" v-model="nuevaRelacion.topeLista"
                                                    dense :error-messages="errors" style="width: 23.4rem" label="Tope lista" 
                                                    @keypress="validarLetra" @paste.prevent>
                                                </v-text-field>
                                            </validation-provider>
                                        </div>
                                    </div>
                                    
                                    <!-- Botones de cerrar, guardar y actualizar que se activan cuando se abre el dialogo para relación-->
                                    <div class="d-flex justify-end">
                                        <v-btn color="error" text @click="reset()">
                                            CERRAR
                                        </v-btn>
                                        <v-btn depressed :disabled="validacionExitosa || validacionActivada || invalid" color="success" text
                                            @click="guardarRelacion()">
                                            GUARDAR
                                        </v-btn>
                                    </div>
                                </v-form>
                            </validation-observer>
                        </v-card-text>
                    </v-tab-item>

                    <v-tab-item>
                        <v-card-text class="pa-4">
                            <validation-observer v-slot="{ invalid }">
                                <v-form ref="archivoSubido" class="mt-3">

                                    <div class="archivo ma-1" v-show="verCSV">
                                        <input type="file" class="seleccionarArchivo" accept=".csv"
                                            @change="lectorCSV($event)" ref="cargaDeRelaciones">
                                        <p v-if="validandoDocumento" style="padding-top: 0.6rem;">
                                            Validando la información del archivo, espere un momento...
                                            <v-icon :class="{ 'rotate-animation': validandoDocumento }"
                                                large>rotate_right
                                            </v-icon>
                                        </p>
                                        <p v-else-if="formatoIncorrecto" style="color: red; margin-top: 1.4rem;">
                                            <v-icon class="mr-2" style="color: red;">error</v-icon>
                                            Formato incorrecto, debe subir o arrastrar un archivo .csv.
                                        </p>
                                        <p v-else>Arrastre un archivo .CSV con las relaciones de productos con bodegas a ingresar,
                                            <br>
                                            También puede hacer click en este cuadro.
                                        </p>
                                    </div>
                                    <v-simple-table class="tablaRelaciones ma-1" fixed-header height="370px" v-show="tabla"
                                        ref="tablaRelacionesMasivas" id="miTabla">
                                        <template v-slot:default>
                                            <thead class="header">
                                                <tr>
                                                    <th> CONTRATO </th>
                                                    <th> PRODUCTO </th>
                                                    <th> TOPE DE UNIDAD </th>
                                                    <th> TOPE DE LISTA </th>
                                                    <th> VALIDACIÓN </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(registro, registroIndex) in registrosFiltrados"
                                                    :key="registroIndex">
                                                    <td>{{ registro.contrato }}</td>
                                                    <td>{{ registro.producto }}</td>
                                                    <td>{{ registro.topeUnidad }}</td>
                                                    <td>{{ registro.topeLista }}</td>
                                                    <td>
                                                        <div
                                                            v-if="validacionesFiltradas[registroIndex] && validacionesFiltradas[registroIndex].length > 0">
                                                            <div v-for="(mensaje, subIndex) in validacionesFiltradas[registroIndex]"
                                                                :key="subIndex" class="error-message">
                                                                {{ mensaje }}
                                                            </div>
                                                        </div>
                                                        <div v-else>
                                                            <v-icon color="green">check_circle</v-icon>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>

                                    <!-- Botones de cerrar, guardar y actualizar que se activan cuando se abre el dialogo para relación-->
                                    <div class="d-flex justify-end">
                                        <v-btn color="error" text @click="reset()">
                                            CERRAR
                                        </v-btn>
                                        <v-btn v-if="mostrarBotones" color="blue" text
                                            @click="volverACargarDocumento">VOLVER</v-btn>
                                        <v-btn v-if="mostrarBotones" depressed :disabled="hayErrores || invalid"
                                            color="success" text @click="guardarMultiplesRelaciones()">
                                            GUARDAR
                                        </v-btn>
                                    </div>
                                </v-form>
                            </validation-observer>
                        </v-card-text>
                    </v-tab-item>
                </v-tabs-items>
            </v-card>
        </v-dialog>

        <!-- Tabla que muestra los registros de la relación contrato-producto-->
        <v-data-table :loading="loading" fixed-header :headers="headersRelaciones" :items="listaDeRelaciones"
            :page.sync="pagina" :items-per-page.sync="itemsPorPagina" :server-items-length="totalPaginas"
            class="elevation mt-4" height="66vh" :footer-props="footerPropsTabla">
            <template v-slot:body="{ items }">
                <tbody>
                    <tr v-for="(item) in items" :key="item.index">
                        <td>
                            {{ item.idContrato }} - {{ item.nombreContrato }}
                        </td>
                        <td>
                            {{ item.codigoProducto }} - {{ item.descripcionProducto }}
                        </td>
                        <td>
                            {{ item.topeUnidad }}
                        </td>
                        <td>
                            {{ item.topeLista }}
                        </td>
                        <td>
                            <v-icon :color="item.eliminado === false ? 'success' : 'error'"> 
                                {{ item.eliminado === false ? 'check_circle' : 'cancel' }}
                            </v-icon>
                            {{ item.eliminado === false ? 'Activo' : 'Inactivo' }}
                        </td>
                        <td class="text-center">
                            <v-tooltip bottom :color="(item.estadoContrato === true || item.estadoProducto === true) ? 'grey' : (item.eliminado === false ? 'error' : 'success')">
                                <template v-slot:activator="{ on, attrs }">
                                    <v-btn v-bind="attrs" v-on="on" icon @mousedown.prevent="item.estadoContrato !== true && item.estadoProducto !== true ? abrirDialogoEstado(item) : null">
                                        <v-icon :color="(item.estadoContrato === true || item.estadoProducto === true) ? 'grey' : (item.eliminado === false ? 'error' : 'success')">
                                            {{ item.eliminado === false ? 'person_add_disabled' : 'how_to_reg' }}
                                        </v-icon>
                                    </v-btn>
                                </template>
                                <span v-if="item.estadoContrato === true || item.estadoProducto === true">Contrato y/o<br>producto inactivo</span>
                                <span v-else>{{ item.eliminado === false ? 'Inactivar' : 'Activar' }}</span>
                            </v-tooltip>
                        </td>
                    </tr>
                    <tr v-if="calcularNoHayEscritura() && !noHayResultadosBusqueda">
                        <td :colspan="headersRelaciones.length + 1" class="mensaje">
                            <span><v-icon class="icono"> feedback </v-icon></span>
                            <div>
                                <em>No hay registros, utilice los filtros para buscar un contrato relacionado con un producto.
                                </em>
                            </div>
                        </td>
                    </tr>
                    <tr v-else-if="noHayResultadosBusqueda">
                        <td :colspan="headersRelaciones.length + 1" class="mensaje">
                            <span><v-icon color="#ff5252" class="icono">search_off</v-icon></span>
                            <div>
                                <em style="color: #ff5252;">No se encontraron resultados para la búsqueda, ingrese un nuevo valor válido.</em>
                            </div>
                        </td>
                    </tr>
                </tbody>
            </template>
            <template v-slot:footer.page-text="items">
                {{ items.pageStart }} - {{ items.pageStop }} de {{ items.itemsLength }}
            </template>
        </v-data-table>

        <!-- dialogo para cambiar el estado -->
        <v-dialog v-model="dialogoCambiarEstado" transition="dialog-bottom-transition" max-width="22.7rem" persistent>
            <v-card>
                <v-card-title class="fondoDialog">
                    <span class="text-h6">{{ mensajeEstado }}</span>
                </v-card-title>
                <v-card-text>
                    <div class="d-flex justify-end" style="padding-top: 1.3rem">
                        <v-btn color="error" text @click="dialogoCambiarEstado = false">No</v-btn>
                        <v-btn color="success" depressed text @click="cambiarEstado()">Si</v-btn>
                    </div>
                </v-card-text>
            </v-card>
        </v-dialog>
    </v-main>
</template>

<!-- #################################################################################### -->
<!-- ###### Sección de Scripts                                                     ###### -->
<!-- #################################################################################### -->
<script>
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import { mapMutations, mapState } from "vuex";
import { Role } from "@/router/role.js";
import * as rules from 'vee-validate/dist/rules';
import { required } from 'vee-validate/dist/rules';
import Vue from "vue";
import Papa from 'papaparse';

Object.keys(rules).forEach((rule) => {
    extend(rule, rules[rule]);
});

extend('required', {
    ...required,
    message: 'Este campo es requerido',
});

let enterpriseCode = null;

/*Validación para el campo de texto,
* Valida que en el campo de texto se eliga un valor de la lista,
* Valida que el contrato este activa en base de datos.
*/
extend('contratoVacio', {
    validate: async (value) => {
        const tieneGuion = value.includes(' - ');
        if (!tieneGuion) {
            return 'Debes elegir un resultado de la lista';
        }
        
        const valorRecortado = value.trim().toLowerCase();
        const [idContrato, ...descripcionParts] = valorRecortado.split(' - ');
        const nombreContrato = descripcionParts.join(' - ');
        if (idContrato.length === 0 || nombreContrato.length === 0) {
            return 'No se encontraron resultados para la búsqueda';
        }
        
        try {
            const response = await Vue.prototype.$http.get('msa-administration/contrato/resultadoLista', {
                params: {
                    idEmpresa: enterpriseCode,
                    idContrato,
                    nombreContrato,
                },
            });
            const contrato = response.data;
            const valorFormateado = `${idContrato} - ${nombreContrato}`.trim();
            const existeValorFormateado = contrato.some((contratos) => {
                const contratoFormateado = `${contratos.idContrato} - ${contratos.nombreContrato}`.trim().toLowerCase();
                return contratoFormateado === valorFormateado;
            });
            if (!existeValorFormateado) {
                const elValorSeleccionadoExiste = contrato.some((contratos) => {
                    const valorFormateado = `${contratos.idContrato} - ${contratos.nombreContrato}`.trim();
                    return valorFormateado === valorRecortado && contratos.seleccionados === true;
                });
                if (!elValorSeleccionadoExiste) {
                    return 'Debes elegir un resultado de la lista';
                }
            }
            
            // Realizar la llamada al método de Spring Boot
            const responseVerificacion = await Vue.prototype.$http.get('msa-administration/contrato/estadoContrato', {
                params: {
                    idEmpresa: enterpriseCode,
                    idContrato,
                    nombreContrato,
                },
            });
            if (responseVerificacion.data === 'El contrato esta inactivo') {
                return 'El contrato esta inactivo';
            }
            return true;
        } catch (error) {
            return 'Error en la validación extend';
        }
    },
});

/*Validación para el campo de texto,
* Valida que en el campo de texto se eliga un valor de la lista,
* Valida que el producto este activa en base de datos.
*/
extend('productoVacio', {
    validate: async (value) => {
        const tieneGuion = value.includes(' - ');
        if (!tieneGuion) {
            return 'Debes elegir un resultado de la lista';
        }
        
        const valorRecortado = value.trim().toLowerCase();
        const [codigoProducto, ...descripcionParts] = valorRecortado.split(' - ');
        const descripcionProducto = descripcionParts.join(' - ');
        if (codigoProducto.length === 0 || descripcionProducto.length === 0) {
            return 'No se encontraron resultados para la búsqueda';
        }
        
        try {
            //const descripcionCodificada = encodeURIComponent(descripcionProducto);
            const response = await Vue.prototype.$http.get('msa-administration/producto/resultadoLista', {
                params: {
                    codigoProducto,
                    descripcionProducto,
                    idEmpresa: enterpriseCode,
                },
            });
            const producto = response.data;
            const valorFormateado = `${codigoProducto} - ${descripcionProducto}`.trim();
            const existeValorFormateado = producto.some((productos) => {
                const productoFormateado = `${productos.codigoProducto} - ${productos.descripcionProducto}`.trim().toLowerCase();
                return productoFormateado === valorFormateado;
            });
            if (!existeValorFormateado) {
                const elValorSeleccionadoExiste = producto.some((productos) => {
                    const valorFormateado = `${productos.codigoProducto} - ${productos.descripcionProducto}`.trim();
                    return valorFormateado === valorRecortado && productos.seleccionados === true;
                });
                if (!elValorSeleccionadoExiste) {
                    return 'Debes elegir un resultado de la lista';
                }
            }
            
            // Realizar la llamada al método de Spring Boot
            const responseVerificacion = await Vue.prototype.$http.get('msa-administration/producto/estadoProducto', {
                params: {
                    idEmpresa: enterpriseCode,
                    codigoProducto,
                    descripcionProducto,
                },
            });
            if (responseVerificacion.data === 'El producto esta inactivo') {
                return 'El producto esta inactivo';
            }
            return true;
        } catch (error) {
            return 'Error en la validación extend';
        }
    },
});

export default {

    name: "ContratoProducto",
    components: {
        ValidationObserver,
        ValidationProvider,
    },

    data() {
        return {
            buscadorIdContrato: '',
            buscadorNombreContrato: '',
            buscadorIdProducto: '',
            buscadorNombreProducto: '',
            dialog: false,
            opciones: 0,
            buscarTextoContrato: '',
            buscarTextoErrorContrato: '',
            mostrarListaContrato: false,
            listaDeContratos: [],
            buscarTextoProducto: '',
            buscarTextoErrorProducto: '',
            mostrarListaProducto: false,
            validacionActivada : false,
            listaDeProductos: [],
            nuevaRelacion:{
                contrato: '',
                producto: '',
                codigo: '',
                topeUnidad: '',
                topeLista: '',
            },
            eliminado: false,
            estado: 'A',
            usuario: '',
            userRoles: {},
            roles: Role,
            listaDeRelaciones: [],
            pagina: 1,
            totalPaginas: 0,
            itemsPorPagina: 10,
            loading: false,
            headersRelaciones: [
                { text: "CONTRATO", width: "26%", sortable: false, },
                { text: "PRODUCTO", width: "26%", sortable: false, },
                { text: "TOPE UNIDAD",  width: "10%", sortable: false, },
                { text: "TOPE LISTA",  width: "9%", sortable: false, },
                { text: "ESTADO", width: "11%", sortable: false, },
                { text: "ACCIONES", align: "center", width: "5%", sortable: false, }
            ],
            footerPropsTabla: {
                'items-per-page-options': [10, 15, 20, 50],
                'items-per-page-text': 'Items por página:',
                'show-current-page': true,
                'show-first-last-page': true,
            },
            noHayResultadosBusqueda: false,
            dialogoCambiarEstado: false,
            mensajeEstado: '',
            relacionSelecionada: {},
            datosExistentesError: '',
            validacionExitosa: false,
            tabla: false,
            verCSV: true,
            registros: [],
            validaciones: [],
            validandoDocumento: false,
            formatoIncorrecto: false,
            archivoSubido: false,
            filasSeleccionadas: [],
            mostrarBotones: false,
        }
    },

    // Se inicializa la variable userRoles con los roles del usuario autenticado
    created() {
        this.userRoles = this.auth.roles;
        enterpriseCode = this.enterprise.code;
    },

    // Se mapean los estados del store a propiedades computadas
    computed: {
        ...mapState(["auth", "notify", "busy", "enterprise"]),

        // Filtra los contratos en base al texto de búsqueda
        contratosFiltrados() {
            if (this.buscarTextoContrato) {
                const valorDeBusqueda = this.buscarTextoContrato.replace(/-|\s/g, '').toLowerCase();
                return this.listaDeContratos.filter(contratos => {
                    const nombreContrato = contratos.text.replace(/-|\s/g, '').toLowerCase(); 
                    return new RegExp(`.*${valorDeBusqueda}.*`).test(nombreContrato);
                });
            } else {
                return this.listaDeContratos;
            }
        },

        // Filtra los productos en base al texto de búsqueda
        productosFiltrados() {
            if (this.buscarTextoProducto) {
                const valorDeBusqueda = this.buscarTextoProducto.replace(/-|\s/g, '').toLowerCase();
                return this.listaDeProductos.filter(productos => {
                    const nombreProducto = productos.text.replace(/-|\s/g, '').toLowerCase(); 
                    const regex = new RegExp(valorDeBusqueda.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'), 'i');
                    return regex.test(nombreProducto);
                });
            } else {
                return this.listaDeProductos;
            }
        },

        /**
        * Devuelve la cantidad de registros que tienen mensajes de validación.
        * @returns {number} - La cantidad de registros con mensajes de validación.
        */
        registrosConError() {
            const registrosConError = this.validaciones.filter(validacion => validacion.length > 0);
            return registrosConError.length;
        },

        /**
        * Filtra y devuelve los registros que tienen mensajes de validación.
        * Si no hay registros con mensajes de validación, devuelve todos los registros.
        * @returns {Array} - Los registros que tienen mensajes de validación o todos los registros si no hay errores.
        */
        registrosFiltrados() {
            if (this.registrosConError > 0) {
                const registrosFiltrados = this.registros.filter((registro, index) => this.validaciones[index].length > 0);
                return registrosFiltrados;
            }
            return this.registros;
        },

        /**
        * Filtra y devuelve las validaciones que tienen mensajes de error.
        * Si no hay registros con mensajes de validación, devuelve todas las validaciones.
        * @returns {Array} - Las validaciones que tienen mensajes de error o todas las validaciones si no hay errores.
        */
        validacionesFiltradas() {
            if (this.registrosConError > 0) {
                return this.validaciones.filter((validacion, index) => this.validaciones[index].length > 0);
            }
            return this.validaciones;
        },

        /**
        * Verifica si existen errores en las validaciones.
        * @returns {boolean} - true si hay al menos un registro con mensajes de validación, false en caso contrario.
        */
        hayErrores() {
            return this.validaciones.some(validacion => validacion.length > 0);
        },
    },

    // Se vigila el cambio en las variables y se ejecuta listarRelaciones()
    watch: {
        pagina: function () {
            this.listarRelaciones();
        },
        itemsPorPagina: function () {
            this.pagina = 1;
            this.listarRelaciones();
        },
        'buscadorIdContrato': function () {
            this.pagina = 1;
            this.listarRelaciones();
        },
        'buscadorIdProducto': function () {
            this.pagina = 1;
            this.listarRelaciones();
        },
        'buscadorNombreProducto': function () {
            this.pagina = 1;
            this.listarRelaciones();
        },
        'buscadorNombreContrato': function () {
            this.pagina = 1;
            this.listarRelaciones();
        },
        'nuevaRelacion.contrato': function () {
            this.validarExistencia();
        },
        'nuevaRelacion.producto': function () {
            this.validarExistencia();
        },
    },

    // Se asigna el valor del username del usuario autenticado a usuario en mayúsculas
    mounted() {
        this.usuario = this.auth.username.toUpperCase();
    },

    methods: {
        // Se mapean las mutaciones del store a métodos locales
        ...mapMutations([
            "updateAuth",
            "hideNotify",
            "showSuccess",
            "showBusy",
            "hideBusy",
        ]),

        /**
         * Función para validar la entrada de caracteres en un evento de tecla.
         * Se encarga de validar que solo se ingresen ciertos caracteres permitidos.
         * @param {Event} event - Evento de tecla
         */
        validarLetra(event) {
            const teclaPresionada = event.key
            if (teclaPresionada.match(/[^0-9]/)) {
                event.preventDefault();
            }
        },

        /**
         * Obtiene la lista de relaciones mediante una solicitud HTTP GET al servidor.
         * Las relaciones se obtienen con paginación y se filtran según los parámetros especificados.
         * Se actualiza el número total de páginas.
         * Además, se controla el estado de carga durante la solicitud.
         */
        listarRelaciones() {
            this.loading = true;
            if (this.buscadorIdProducto === '' && this.buscadorIdContrato === '' && this.buscadorNombreProducto === '' && this.buscadorNombreContrato === '') {
                this.listaDeRelaciones = [];
                this.loading = false;
                this.totalPaginas = 0;
                this.noHayResultadosBusqueda = false;
                return;
            }
            this.$http
                .get(`msa-administration/contratoProducto/listar`, {
                    params: {
                        page: this.pagina - 1,
                        size: this.itemsPorPagina,
                        contrato: `${this.buscadorIdContrato}`,
                        nombreContrato: `${this.buscadorNombreContrato.toLowerCase()}`,
                        producto: `${this.buscadorIdProducto}`,
                        nombreProducto: `${this.buscadorNombreProducto.toLowerCase()}`,
                        idEmpresa: this.enterprise.code,
                    },
                })
                .then(response => {
                    this.listaDeRelaciones = response.data.content;
                    // Actualizar el número total de páginas
                    this.totalPaginas = response.data.totalElements;
                    if (response.data.content.length === 0) {
                        this.noHayResultadosBusqueda = true;
                    } else {
                        this.noHayResultadosBusqueda = false;
                    }
                    this.loading = false;
                }).catch(error => {
                    console.log(error)
                    this.loading = false;
                })
        },

        /**
         * Calcula si no hay escritura en los campos de búsqueda
         * @returns {boolean} - Indicador de no hay escritura
         */
        calcularNoHayEscritura() {
            return this.buscadorIdProducto === '' && this.buscadorIdContrato === '' && this.buscadorNombreProducto === '' && this.buscadorNombreContrato === '';
        },

        /**
         * Actualiza la selección de un contrato en el componente en respuesta a una elección del usuario.
         * @param contratos El objeto contratos seleccionado.
         * @description Si la elección es "No se encontraron resultados", oculta la lista de contratos.
         */
        seleccionDeContrato(contratos) {
            if (contratos.text === 'No se encontraron resultados') {
                this.mostrarListaContrato = false;
                return;
            }
            this.buscarTextoContrato = contratos.text.trim();
            this.nuevaRelacion.contrato = contratos.value;
            this.mostrarListaContrato = true;
        },

        /**
         * Realiza una búsqueda de contratos en el servidor en función del texto ingresado y actualiza la lista
         * de contratos en el componente.
         * @throws {Error} Si ocurre un error durante la solicitud al servidor.
         */
        async buscarContrato() {
            this.buscarTextoErrorContrato = '';
            if (this.buscarTextoContrato) {
                try {
                    const busqueda = this.buscarTextoContrato.toLowerCase().trim();
                    const valorDeBusqueda = this.buscarTextoContrato.replace(/-|\s/g, '');
                    let idContrato = '';
                    let nombreContrato = '';
                    let valorId = busqueda;
                    let valorNombre = busqueda;

                    // Verifica si la entrada contiene un guion
                    if (busqueda.includes(' - ')) {
                        [valorId, valorNombre] = busqueda.split(' - ');
                    } else if (busqueda.includes('-')) {
                        // Si contiene guion, separa los valores antes y después del guion
                        const partes = busqueda.split('-');
                        if (partes.length === 2) {
                            valorId = partes[0].trim();
                            valorNombre = partes[1].trim();
                        }
                    }

                    // Asigna los valores a 'idContrato' y 'nombreContrato' sin guiones
                    idContrato = valorId.replace(/-/g, '');
                    nombreContrato = valorNombre.replace(/-/g, '');

                    const response = await this.$http.get(`msa-administration/contrato/resultadoLista`, {
                        params: {
                            idEmpresa: this.enterprise.code,
                            nombreContrato: nombreContrato,
                            idContrato: idContrato,
                        }
                    });
                    const resultado = response.data.map((item) => ({
                        text: `${item.idContrato} - ${item.nombreContrato}`,
                        value: item.idContrato,
                    }));
                    const existeContrato = resultado.some((contratos) => {
                        const valor = contratos.text.replace(/-|\s/g, '').toLowerCase();
                        return valor.includes(valorDeBusqueda.toLowerCase());
                    })
                    this.listaDeContratos = existeContrato ? resultado : [];
                    this.mostrarListaContrato = existeContrato;
                    if (!existeContrato) {
                        this.buscarTextoErrorContrato = 'No se encontraron resultados para la búsqueda';
                        this.validacionActivada = true;
                    } else{
                        this.validacionActivada = false;
                    }
                    return this.listaDeContratos;
                    
                } catch(error) {
                    console.log(error);
                }
            } else {
                this.listaDeContratos = [];
                this.mostrarListaContrato = false;
            }
        },

        /**
         * Actualiza la selección de un producto en el componente en respuesta a una elección del usuario.
         * @param productos El objeto productos seleccionado.
         * @description Si la elección es "No se encontraron resultados", oculta la lista de productos.
         */
        seleccionDeProducto(productos) {
            if (productos.text === 'No se encontraron resultados') {
                this.mostrarListaProducto = false;
                return;
            }
            this.buscarTextoProducto = productos.text.trim();
            this.nuevaRelacion.producto = productos.value;
            this.nuevaRelacion.codigo = productos.codigo;
            this.mostrarListaProducto = true;
        },

        /**
         * Realiza una búsqueda de productos en el servidor en función del texto ingresado y actualiza la lista
         * de productos en el componente.
         * @throws {Error} Si ocurre un error durante la solicitud al servidor.
         */
        async buscarProducto() {
            this.buscarTextoErrorProducto = '';
            if (this.buscarTextoProducto) {
                try {
                    const busqueda = this.buscarTextoProducto.toLowerCase().trim();
                    const valorDeBusqueda = this.buscarTextoProducto.replace(/-|\s/g, '');
                    let codigoProducto = '';
                    let descripcionProducto = '';
                    let valorProducto = busqueda;
                    let valorDescripcion = busqueda;

                    // Verifica si la entrada contiene un guion
                    if (busqueda.includes(' - ')) {
                        [valorProducto, valorDescripcion] = busqueda.split(' - ');
                    } else if (busqueda.includes('-')) {
                        // Si contiene guion, separa los valores antes y después del guion
                        const partes = busqueda.split('-');
                        if (partes.length === 2) {
                            valorProducto = partes[0].trim();
                            valorDescripcion = partes[1].trim();
                        }
                    }

                    // Asigna los valores a 'codigoProducto' y 'descripcionProducto' sin guiones
                    codigoProducto = valorProducto.replace(/-/g, '');
                    descripcionProducto = valorDescripcion.replace(/-/g, '');

                    const response = await this.$http.get(`msa-administration/producto/resultadoLista`, {
                        params: {
                            codigoProducto: codigoProducto,
                            descripcionProducto: descripcionProducto,
                            idEmpresa: this.enterprise.code,
                        }
                    });
                    const resultado = response.data.map((item) => ({
                        text: `${item.codigoProducto} - ${item.descripcionProducto}`,
                        value: item.codigoProducto,
                        codigo: item.codigoProducto,
                    }));
                    const existeProducto = resultado.some((productos) => {
                        const valor = productos.text.replace(/-|\s/g, '').toLowerCase();
                        return valor.includes(valorDeBusqueda.toLowerCase());
                    })
                    this.listaDeProductos = existeProducto ? resultado : [];
                    this.mostrarListaProducto = existeProducto;
                    if (!existeProducto) {
                        this.buscarTextoErrorProducto = 'No se encontraron resultados para la búsqueda';
                        this.validacionActivada = true;
                    } else{
                        this.validacionActivada = false;
                    }
                    return this.listaDeProductos;
                    
                } catch(error) {
                    console.log(error);
                }
            } else {
                this.listaDeProductos = [];
                this.mostrarListaProducto = false;
            }
        },

        /**
         * Se encarga de realizar una validación para verificar si existe una relación entre el id del contrato 
         * y el código de producto seleccionados en la tabla contrato_producto de postgres.
         */
        validarExistencia() {
            if (this.nuevaRelacion.contrato && this.nuevaRelacion.producto) {
                this.$http
                    .get("msa-administration/contratoProducto/validarExistencia", {
                        params: {
                            idContrato: this.nuevaRelacion.contrato,
                            codigoProducto: this.nuevaRelacion.producto
                        }
                    })
                    .then((response) => {
                        if (response.data !== "La relación no existe") {
                            this.datosExistentesError = "La relación ya existe.";
                            this.validacionExitosa = true; // La validación no fue exitosa
                        } else {
                            this.datosExistentesError = "";
                            this.validacionExitosa = false; // La validación fue exitosa
                        }
                    })
                    .catch((error) => {
                        console.log(error);
                        this.datosExistentesError = "Error en la validación de datos";
                        this.validacionExitosa = true; // La validación no fue exitosa
                    });
            } else {
                this.datosExistentesError = "";
                this.validacionExitosa = false;
            }
        },

        /**
         * Guarda la información de una nueva relación en el servidor.
         * Crea un objeto con los datos de la relación, incluyendo la fecha de creación actual,
         * y realiza una solicitud al servidor para ingresar la relación. Luego, actualiza la lista
         * de las relaciones y restablece el formulario.
         */
        guardarRelacion() {
            const horaActual = new Date;
            // Realiza la petición al backend para guardar la relación
            const relacionarProducto = {
                idCopr: null,
                idContrato: this.nuevaRelacion.contrato,
                idPoducto: null,
                idEmpresa: this.enterprise.code,
                codigoProducto: this.nuevaRelacion.codigo,
                topeUnidad: Number(this.nuevaRelacion.topeUnidad),
                topeLista: Number(this.nuevaRelacion.topeLista),
                fecha: horaActual.toISOString(),
                eliminado: this.eliminado,
                usuario: this.usuario,
            };
            const relacionarProductos = [relacionarProducto];
            // Realizar la petición POST al controlador para guardar la relación
            this.$http
                .post(`msa-administration/contratoProducto/crear`, relacionarProductos)
                .then(() => {
                    this.listarRelaciones();
                    this.reset();
                })
                .catch(error => {
                    console.error(error);
                });
        },
        
       /**
         * Abre el diálogo para cambiar el estado de una relación.
         * Establece la propiedad 'dialogoCambiarEstado' en true para mostrar el diálogo.
         * Guarda la relación seleccionada en la propiedad 'relacionSelecionada'.
         * Este método se invoca cuando se desea cambiar el estado de una relación.
         * @param {object} item - El relación seleccionada.
         */
        abrirDialogoEstado(item) {
            this.dialogoCambiarEstado = true;
            this.relacionSelecionada = item;
            this.mensajeEstado = this.relacionSelecionada.eliminado === false ? '¿Desea inactivar la relación?' : '¿Desea activar la relación?';
            setTimeout(() => {
                this.mensajeEstado = this.relacionSelecionada.eliminado === false ? '¿Desea inactivar la relación?' : '¿Desea activar la relación?';
            }, 4000);
        },

        /**
         * Cambia el estado de una relación.
         * Actualiza el estado de la relación seleccionada ('relacionSelecionada') según la lógica definida.
         * Realiza una solicitud HTTP PUT al servidor para actualizar el estado de la relación.
         * Después de la actualización exitosa, se registra el mensaje y se actualiza la lista de relaciones.
         */
        cambiarEstado() {
            this.relacionSelecionada.eliminado = (this.relacionSelecionada.eliminado == false) ? true : false;
            const horaActual = new Date;
            const cambiarEstado = {
                idCopr: this.relacionSelecionada.idCopr,
                idContrato: this.relacionSelecionada.idContrato,
                idPoducto: null,
                idEmpresa: this.enterprise.code,
                codigoProducto: this.relacionSelecionada.codigoProducto,
                topeUnidad: this.relacionSelecionada.topeUnidad,
                topeLista: this.relacionSelecionada.topeLista,
                eliminado: this.relacionSelecionada.eliminado,
                fecha: horaActual.toISOString(),
                usuario: this.usuario,
            };
            this.$http
                .put(`msa-administration/contratoProducto/cambiarEstado`, cambiarEstado)
                .then(() => {
                    this.dialogoCambiarEstado = false;
                    this.listarRelaciones();
                }).catch(error => {
                    console.log(error);
                })
        },

        /**
         * Método para leer la información de un archivo CSV, realizar validaciones en el backend
         * y mostrar los resultados en una tabla.
         * Este método se encarga de leer el contenido de un archivo CSV seleccionado mediante un
         * input de tipo archivo. Luego, procesa los datos y realiza una serie de validaciones en el
         * backend usando Promises. Finalmente, muestra los registros válidos en una tabla en la interfaz.
         * @param {Event} event - El evento del input de tipo archivo que contiene el archivo CSV.
         */
        lectorCSV(event) {
            const file = event.target.files[0];
            // Verificar si el archivo tiene la extensión .csv
            if (!file || file.name.split('.').pop() !== 'csv') {
                this.mostrarMensajeErrorFormatoIncorrecto();
                return;
            }
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target.result;
                const parsedData = Papa.parse(content, {
                    header: false,
                    delimiter: ',',
                });
                const registros = [];
                const headerRow = parsedData.data[0]; // Primera fila del archivo.
                const isHeaderRow = /^\d+$/.test(headerRow[0]) && /^\d+$/.test(headerRow[1]);
                // Inicio en 1 si es una fila de encabezados, de lo contrario empiezo en 0.
                for (let index = isHeaderRow ? 0 : 1; index < parsedData.data.length - 1; index++) {
                    const row = parsedData.data[index];
                    const contrato = /^\d+$/.test(row[0]) ? parseInt(row[0]) : '';
                    const producto = /^\d+$/.test(row[1]) ? parseInt(row[1]) : '';
                    const topeUnidad = /^\d+$/.test(row[2]) ? parseInt(row[2]) : 0;
                    const topeLista = /^\d+$/.test(row[3]) ? parseInt(row[3]) : 0;
                    if (row[0] !== '' || row[1] !== '') {
                        registros.push({
                            contrato: contrato,
                            producto: producto,
                            topeUnidad: topeUnidad,
                            topeLista: topeLista,
                        });
                    }
                }
                this.validandoDocumento = true;
                this.registros = registros;
                this.archivoSubido = true;
                this.validaciones = Array.from({ length: this.registros.length }, () => []);
                const backendCalls = [
                    this.validarExistenciaDeRelaciones(registros),
                    this.validarEstadoDelContrato(registros),
                    this.validarEstadoDelProducto(registros),
                ];
                Promise.all(backendCalls)
                    .then(() => {
                        this.$nextTick(() => {
                            this.verCSV = false;
                            this.tabla = true;
                            this.mostrarBotones = true;
                            this.validandoDocumento = false;
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                        this.validandoDocumento = false;
                        this.mostrarBotones = false;
                    });
            };
            reader.readAsText(file);
        },

        /** Muestra un mensaje de error cuando el formato del archivo es incorrecto.*/
        mostrarMensajeErrorFormatoIncorrecto() {
            // Actualiza una variable para mostrar el mensaje de error en la interfaz
            this.formatoIncorrecto = true;
        },

        /**
         * Valida las relaciones entre los id de contratos y los códigos de producto en el backend (contrato_producto).
         * @param {Object[]} registros - La lista de registros válidos a validar en el backend.
         * @returns {Promise} - Una Promise que se resuelve cuando se completan las validaciones o se rechaza en caso de error.
         */
        validarExistenciaDeRelaciones(registros) {
            const registrosNumericos = [];
            const mensajesErrores = {};

            registros.forEach((registro, index) => {
                if (/^\d+$/.test(registro.contrato) && /^\d+$/.test(registro.producto)) {
                    registrosNumericos.push({ codigoProducto: registro.producto, idContrato: registro.contrato, idEmpresa: this.enterprise.code });
                } else {
                    registrosNumericos.push({ codigoProducto: '', idContrato: '', idEmpresa: this.enterprise.code }); // Marca como vacíos los registros no numéricos.

                    // Verificar si los campos están vacíos o nulos
                    if (registro.contrato === "" && registro.producto === "" ) {
                        mensajesErrores[index] = "Los campos para el código del contrato y el código de producto no pueden estar vacíos";
                    } else if (registro.contrato === null || registro.contrato === "") {
                        mensajesErrores[index] = "El campo para el código del contrato no puede estar vacío";
                    } else if (registro.producto === null || registro.producto === "") {
                        mensajesErrores[index] = "El campo para el código de producto no puede estar vacío";
                    } else {
                        mensajesErrores[index] = "Los campos solo permiten caracteres numéricos";
                    }

                    this.validaciones[index].push(mensajesErrores[index]);
                }
            });
            return this.$http
                .post("/msa-administration/contratoProducto/validarExistenciaDeRelaciones", registrosNumericos)
                .then((response) => {
                    this.filasSeleccionadas = [];
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        response.data.forEach((mensaje, index) => {
                            if (mensaje.trim() !== '') {
                                if (!Array.isArray(this.validaciones[index])) {
                                    this.validaciones[index] = [];
                                }
                                this.validaciones[index].push(mensaje);
                            }
                        });
                    }
                    this.verCSV = true;
                    this.validandoDocumento = true;
                })
                .catch((error) => {
                    console.error(error);
                    this.validandoDocumento = true;
                    return Promise.reject(error);
                });
        },

        /**
         * Valida el estado del contrato en el backend (contrato).
         *  @param {Object[]} registros - La lista de registros válidos a validar en el backend.
         * @returns {Promise} - Una Promise que se resuelve cuando se completan las validaciones o se rechaza en caso de error.
         */
        validarEstadoDelContrato(registros) {
            const registrosNumericos = [];
            registros.forEach((registro) => {
                if (/^\d+$/.test(registro.contrato) && /^\d+$/.test(registro.producto)) {
                    registrosNumericos.push({ idContrato: registro.contrato, idEmpresa: this.enterprise.code });
                } else {
                    registrosNumericos.push({ idContrato: '' , idEmpresa: this.enterprise.code }); // Marca como nulo los registros no numéricos.
                }
            });
            return this.$http
                .post("/msa-administration/contrato/validarEstadoContrato", registrosNumericos)
                .then((response) => {
                    this.filasSeleccionadas = [];
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        response.data.forEach((mensaje, index) => {
                            if (mensaje.trim() !== '') {
                                if (!Array.isArray(this.validaciones[index])) {
                                    this.validaciones[index] = [];
                                }
                                this.validaciones[index].push(mensaje);
                            }
                        });
                    }
                    this.verCSV = true;
                    this.validandoDocumento = true;
                })
                .catch((error) => {
                    console.error(error);
                    this.validandoDocumento = true;
                    return Promise.reject(error);
                });
        },

        /**
         * Valida el estado del producto en el backend (producto).
         * @param {Object[]} registros - La lista de registros válidos a validar en el backend.
         * @returns {Promise} - Una Promise que se resuelve cuando se completan las validaciones o se rechaza en caso de error.
         */
        validarEstadoDelProducto(registros) {
            const registrosNumericos = [];
            registros.forEach((registro) => {
                if (/^\d+$/.test(registro.contrato) && /^\d+$/.test(registro.producto)) {
                    registrosNumericos.push({ codigoProducto: registro.producto, idEmpresa: this.enterprise.code });
                } else {
                    registrosNumericos.push({ codigoProducto: '', idEmpresa: this.enterprise.code }); // Marca como nulo los registros no numéricos.
                }
            });
            return this.$http
                .post("/msa-administration/producto/validarEstadoProducto", registrosNumericos)
                .then((response) => {
                    this.filasSeleccionadas = [];
                    if (Array.isArray(response.data) && response.data.length > 0) {
                        response.data.forEach((mensaje, index) => {
                            if (mensaje.trim() !== '') {
                                if (!Array.isArray(this.validaciones[index])) {
                                    this.validaciones[index] = [];
                                }
                                this.validaciones[index].push(mensaje);
                            }
                        });
                    }
                    this.verCSV = true;
                    this.validandoDocumento = true;
                })
                .catch((error) => {
                    console.error(error);
                    this.validandoDocumento = true;
                    return Promise.reject(error);
                });
        },

        /**
         * Reinicia el estado del componente para volver a cargar un nuevo documento CSV.
         * Este método se encarga de reiniciar todas las variables de estado relacionadas con la
         * carga de documentos y las validaciones de manera que se pueda seleccionar y cargar
         * un nuevo archivo CSV para ser procesado nuevamente.
         */
        volverACargarDocumento() {
            this.validaciones = [];
            this.registros = [];
            this.archivoSubido = false;
            this.filasSeleccionadas = [];
            this.validandoDocumento = false;
            this.formatoIncorrecto = false;
            this.verCSV = true;
            this.mostrarBotones = false;
            this.tabla = false;
            this.$refs.cargaDeRelaciones.value = '';
        },

        /**
         * Guarda las relaciones del archivo en forma masiva en la base de datos.
         * Este método toma los registros válidos del archivo previamente cargado y filtrados
         * para asegurarse de que no haya registros duplicados. Luego, realiza una petición al
         * backend para guardar las relaciones de contrato_producto en la base de datos.
         */
        guardarMultiplesRelaciones() {
            const horaActual = new Date();
            const registrosUnicos = this.registros.filter((registro, index, self) =>
                index === self.findIndex((r) => r.contrato === registro.contrato && r.producto === registro.producto)
            );
            if (registrosUnicos.length === 0) {
                return;
            }
            const relacionarProductos = registrosUnicos.map((registro) => {
                return {
                    idCopr: null,
                    idContrato: registro.contrato,
                    idPoducto: null,
                    idEmpresa: this.enterprise.code,
                    codigoProducto: registro.producto,
                    topeUnidad: Number(registro.topeUnidad),
                    topeLista: Number(registro.topeLista),
                    fecha: horaActual.toISOString(),
                    eliminado: this.eliminado,
                    usuario: this.usuario,
                };
            });

            this.$http
                .post(`msa-administration/contratoProducto/crear`, relacionarProductos)
                .then(() => {
                    this.listarRelaciones();
                    this.reset();
                })
                .catch(error => {
                    console.error(error);
                });
        },

        /**
         * Restablece los valores de los campos y las variables relacionadas a la creación de una relación.
         * Establece los valores predeterminados y cierra los cuadros de diálogo abiertos.
         * Reinicia el estado de edición y activación de campos.
         * Reinicia el estado del componente de observación (refs.observer).
         */
        reset() {
            this.dialog = false;
            this.opciones = 0;
            this.buscarTextoContrato = '';
            this.buscarTextoErrorContrato = '';
            this.mostrarListaContrato = false,
            this.buscarTextoProducto = '';
            this.buscarTextoErrorProducto = '';
            this.mostrarListaProducto = false,
            this.nuevaRelacion.contrato = '';
            this.nuevaRelacion.producto = '';
            this.nuevaRelacion.topeUnidad = '';
            this.nuevaRelacion.topeLista = '';
            this.datosExistentesError = '';
            this.dialogoCambiarEstado = false;
            this.$refs.observer?.reset();
            this.validacionExitosa = false;
            this.validaciones = [];
            this.archivoSubido = false;
            this.filasSeleccionadas = [];
            this.validandoDocumento = false;
            this.formatoIncorrecto = false;
            this.verCSV = true;
            this.mostrarBotones = false;
            this.tabla = false;
            if (this.$refs.cargaDeRelaciones) {
                this.$refs.cargaDeRelaciones.value = '';
            }
        },
    },
}
</script>

<!-- #################################################################################### -->
<!-- ###### Sección de Estilos                                                     ###### -->
<!-- #################################################################################### -->
<style scoped>
::v-deep .elevation div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}

::v-deep .elevation div table thead tr th span {
    font-weight: bold;
    color: black !important;
}

::v-deep .elevation .v-data-footer {
    width: 100%;
}

::v-deep .elevation .v-data-footer__select .v-select {
    margin: 5px;
    margin-left: 10px;
}

::v-deep .elevation .v-data-table__wrapper {
    border: 1px solid #f7f6f6;
}

.fondoDialog {
    background-color: #1867c0;
    color: rgb(223, 223, 223) !important;
}

.mensaje {
    text-align: center;
    padding-top: 120px !important;
}

.mensaje em {
    font-size: larger;
    color: rgb(161 161 162);
}

.mensaje:hover {
    background-color: white;
}

.icono {
    font-size: 50px;
}

::v-deep .v-tabs .v-tabs-slider-wrapper {
    top: 80% !important;
}

::v-deep .tablaRelaciones div table thead tr th {
    background-color: rgb(223, 223, 223) !important;
}

.header tr {
    background-color: rgb(223, 223, 223) !important;
}

.header tr th {
    font-weight: bold;
    color: black !important;
}

.archivo {
    outline: 2px dashed grey;
    outline-offset: -10px;
    background: lightcyan;
    color: dimgray;
    padding: 1rem;
    height: 100px;
    position: relative;
    cursor: pointer;
}

.archivo p {
    margin-top: .7rem;
    text-align: center;
}

.seleccionarArchivo {
    opacity: 0;
    width: 100%;
    height: 100px;
    position: absolute;
    cursor: pointer;
}

.error-message {
    color: red;
}

.rotate-animation {
    animation: rotate 2s linear infinite;
}

@keyframes rotate {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}
.resultadoLista {
    max-height: 12rem;
    overflow-y: auto;
    z-index: 999;
    width: 100%;
}
</style>